'use client';

/**
 * Ripple Effect Hook
 *
 * A custom hook that provides material-design-like ripple effects for UI elements.
 */

import * as React from 'react';
import { ButtonVariant } from '../types';
import './css/ripple.css';

interface RippleProps {
  variant?: ButtonVariant;
  className?: string;
}

export const useRipple = ({ variant, className }: RippleProps = {}) => {
  const hasRippleStyles = React.useMemo(() => {
    try {
      const element = document.createElement('span');
      element.className = 'ripple';
      document.body.appendChild(element);
      const styles = window.getComputedStyle(element);
      const hasStyles = styles.position === 'absolute';
      document.body.removeChild(element);
      return hasStyles;
    } catch (e) {
      return false;
    }
  }, []);

  const createRipple = React.useCallback(
    (event: React.MouseEvent<HTMLElement>) => {
      if (!hasRippleStyles) return;

      const element = event.currentTarget;
      const rect = element.getBoundingClientRect();
      const diameter = Math.sqrt(rect.width ** 2 + rect.height ** 2) * 2;
      const isDarkRipple = variant === 'outlined' || variant === 'ghost';

      // Remove existing ripple
      element.getElementsByClassName('ripple')[0]?.remove();

      // Create and position new ripple
      const circle = document.createElement('span');
      Object.assign(circle.style, {
        width: `${diameter}px`,
        height: `${diameter}px`,
        left: `${event.clientX - rect.left - diameter / 2}px`,
        top: `${event.clientY - rect.top - diameter / 2}px`,
      });

      // Add classes
      circle.classList.add(
        'ripple',
        isDarkRipple ? 'ripple-dark' : 'ripple-light'
      );

      element.appendChild(circle);
    },
    [hasRippleStyles, variant]
  );

  const removeRipple = React.useCallback(() => {
    if (!hasRippleStyles) return;

    const ripples = document.getElementsByClassName('ripple');
    if (ripples.length) {
      const ripple = ripples[0];
      ripple.classList.add('ripple-release');
      setTimeout(() => ripple.remove(), 700);
    }
  }, [hasRippleStyles]);

  return {
    rippleHandlers: {
      onMouseDown: createRipple,
      onMouseUp: removeRipple,
      onMouseLeave: removeRipple,
    },
    rippleClassName: 'relative overflow-hidden',
  };
};
