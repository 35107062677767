'use client';

import Link from 'next/link';
import { useEffect } from 'react';
import { Button } from 'src/ui/button';

export default function ErrorPage({
  error,
  reset,
}: {
  error: Error & { digest?: string };
  reset: () => void;
}) {
  useEffect(() => {
    // Log the error to an error reporting service
    console.error(error);
  }, [error]);
  return (
    <div className="flex min-h-[100dvh] flex-col items-center justify-center bg-[radial-gradient(ellipse_at_center,_var(--tw-gradient-stops))] from-[#1e293b] to-[#0f172a] px-4 py-12 sm:px-6 lg:px-8">
      <div className="mx-auto max-w-md text-center">
        <div className="inline-flex items-center justify-center rounded-full bg-[#475569] p-4 shadow-lg">
          <span className="text-[72px] font-bold text-[#f1f5f9]">404</span>
        </div>
        <h1 className="mt-6 text-4xl font-bold tracking-tight text-[#f1f5f9] sm:text-5xl">
          Oops, something went wrong!
        </h1>
        <p className="mt-4 text-lg text-[#94a3b8]">
          We couldn&apos;t find the page you were looking for. Please check the
          URL or try again later.
        </p>
        <div className="mt-6">
          <Button
            variant="secondary"
            lowercase={true}
            onClick={
              // Attempt to recover by trying to re-render the segment
              () => reset()
            }
          >
            Try again
          </Button>
        </div>
        <div className="mt-6">
          <Link
            href="#"
            className="inline-flex items-center rounded-md bg-[#0ea5e9] px-4 py-2 text-sm font-medium text-[#f1f5f9] shadow-sm transition-colors hover:bg-[#0284c7] focus:outline-none focus:ring-2 focus:ring-[#0ea5e9] focus:ring-offset-2"
            prefetch={false}
          >
            Go to Homepage
          </Link>
        </div>
      </div>
    </div>
  );
}
