/**
 *
 * A reusable loading spinner component with customizable size and color.
 *
 */

import * as React from 'react';

interface LoadingSpinnerProps {
  /** xs (8px), sm (12px), md (16px), lg (20px), xl (28px), xxl (32px) */
  size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'xxl';

  color?:
    | 'primary'
    | 'secondary'
    | 'success'
    | 'error'
    | 'info'
    | 'warning'
    | 'default';
  className?: string;
}

const sizeMap = {
  xs: 'h-2 w-2',
  sm: 'h-3 w-3',
  md: 'h-4 w-4',
  lg: 'h-5 w-5',
  xl: 'h-6 w-6',
  xxl: 'h-8 w-8',
};

const colorMap = {
  primary: 'text-primary',
  secondary: 'text-secondary',
  success: 'text-success',
  error: 'text-error',
  info: 'text-info',
  warning: 'text-warning',
  default: 'text-gray-500',
};

export const LoadingSpinner = React.forwardRef<
  SVGSVGElement,
  LoadingSpinnerProps
>(({ size = 'sm', color = 'default', className }, ref) => {
  return (
    <svg
      className={`animate-spin ${sizeMap[size]} ${
        color ? colorMap[color] : ''
      } ${className ?? ''}`}
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      ref={ref}
    >
      <circle
        className="opacity-25"
        cx="12"
        cy="12"
        r="10"
        stroke="currentColor"
        strokeWidth="4"
      />
      <path
        className="opacity-75"
        fill="currentColor"
        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
      />
    </svg>
  );
});

LoadingSpinner.displayName = 'LoadingSpinner';
